import React from 'react'
import styled from 'styled-components'
import masthead from '../images/masthead.jpg'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { PageWrapper, Title, Flex } from '../components/UI'
import UPCard from '../components/UPCard'
import LocationSection from '../components/LocationSection'
import ProgramSection from '../components/ProgramSection'
import SearchBox from '../components/SearchBox'
import { breakpoints } from '../utils/helper'
import _ from 'lodash'
import features from '../data/features.json'
import Features from '../components/Features'
import { navigate } from 'gatsby'
import programs from '../data/programs'

const Image = styled.img`
    width: 100%;
`

const SloganBox = styled(Flex)`
    margin: 90px 0 0px;
    flex-direction: column;
    align-items: center;
    
    @media ${breakpoints.tablet} {
        margin: 120px 0 80px;
    }
`

const CenteredTitle = styled(Title)`
    text-align: center;
    margin-bottom: 54px;
    
    @media ${breakpoints.tablet} {
        margin-bottom: 84px;
    }
`

const MastBox = styled(Flex)`
    position: relative;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    background-color: ${props => props.theme.colors.red};
    padding: 86px 24px 24px;
    
    img {
        display: none;
    }
    
    @media ${breakpoints.tablet} {
        max-height: 550px;
        height: 100%;
        margin: 64px auto 0;
        padding: 0;
        
        img {
            display: block;
        }
    }
    
    @media ${breakpoints.desktop} {
        margin: 90px auto 0;
    }
`

const IndexPage = ({location}) => {
    const handleClick = () => navigate("/vestigingen?loc")

    return (
        <Layout location={location}>
            <SEO title="Welkom bij UP Carwash: de wasstraat met aandacht voor elke auto"
                 description="UP Carwash is de wasstraat met vijf verschillende wasprogramma's voor elk budget. Wij wassen elke auto met evenveel oprechte aandacht."/>
            <MastBox>
                <Image src={masthead} alt="Masthead afbeelding Carwash" width="100%" height="auto"/>
                <SearchBox color="#000000" onCurrentLocation={handleClick}/>
            </MastBox>
            <PageWrapper>
                <SloganBox>
                    <CenteredTitle>Aandacht<br/>voor elke auto</CenteredTitle>
                    <Features data={_.get(features, 'features.home')}/>
                </SloganBox>
                <LocationSection/>
            </PageWrapper>
            <ProgramSection programs={programs.items}/>
            <UPCard/>
        </Layout>
    )
}

export default IndexPage
