import React, {useState} from 'react'
import {Flex, PageWrapper, Title} from './UI'
import styled from 'styled-components'
import LocationItem from './LocationItem'
import locationData from "../data/locations.json";
import { breakpoints } from '../utils/helper'

const Container = styled(PageWrapper)`
    background-color: ${props => props.theme.colors.white};
    display: flex;
    padding: 42px 0px 0px;
    justify-content: flex-start;
    flex-direction: column;
    
    @media ${breakpoints.tablet} {
        padding: 78px 30px 42px;
    }
    
    @media ${breakpoints.large} {
        padding: 78px 0px 42px;
    }
`

const StyledTitle = styled(Title)`
    color: ${props => props.theme.colors.black};
    text-align: left;
    margin: 0 0 30px;
    padding: 0px 30px;
    
    @media ${breakpoints.tablet} {
        padding: 0px 0px;
        margin: 0 0 60px;
    }
`

const ButtonBox = styled(Flex)`
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 42px;
    display: none;
    
    @media ${breakpoints.tablet} {
        display: block;
    }
`

const Button = styled.button`
    border: none;
    background: transparent;
    color: ${props => props.theme.colors.grey};
    ${props => props.theme.fontStyle.h4}
    margin: 0 24px 0 0;
    cursor: pointer;
    
    &.active {
        color: ${props => props.theme.colors.black};
    }
`

const LocationSection = () => {
    const [location, setLocation] = useState(locationData.locations[0])

    const handleClick = (data) => setLocation(data)

    return (
        <Container>
            <StyledTitle>Vestigingen</StyledTitle>
            <ButtonBox>
                {locationData.locations.length > 1 && locationData.locations.map((data, i) =>
                    <Button
                        key={i}
                        className={data === location ? 'active' : ''}
                        onClick={() => handleClick(data)}>{data.city}</Button>
                )}
            </ButtonBox>
            <LocationItem data={location} home open link address/>
        </Container>
    )
}

export default LocationSection
